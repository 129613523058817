import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/modal.provider.scss";
import { ReactNode, createContext, useContext, useState } from "react";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// Define the Modal Type
export type Modal = {
    id?: string;
    title?: string;
    content: ReactNode;
    on_confirm?: any;
    on_close?: any;
};

// Define the context interface
export interface ModalContext {
    modals: Modal[];
    create: (modal: Modal) => string | null;
    remove: (id: string | undefined) => void;
}

// Create the context
const ModalContext = createContext<ModalContext>(null!);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modals, setModals] = useState<Modal[]>([]);

    const create = (modal: Modal) => {
        modal.id = Math.random().toString(36).substring(2, 9);
        setModals([...modals, modal]);
        return modal.id || null;
    }

    const remove = (id: string | undefined) => {
        if (id) setModals([...modals.filter((modal: Modal) => modal.id !== id)]);
    }

    return (
        <ModalContext.Provider
            value={{
                modals,
                create,
                remove,
            }}>
            <div className="modal-wrapper">
                <div className="modal-bg"
                    style={{
                        display: !modals.length ? 'none' : 'unset',
                    }} />

                <div className="modals">
                    {modals.map((modal: Modal, i: number) => (
                        <div key={i} className="modal">
                            <div className="modal-inner">
                                <div className="modal-header">
                                    <div className="modal-title">{modal.title}</div>
                                    <div className="modal-close" onClick={() => {
                                        remove(modal.id);
                                        modal.on_close && modal.on_close(modal.id);
                                    }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                                <div className="modal-content">{modal.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {children}

        </ModalContext.Provider>
    )
}

export const useModals = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModals must be inside of a provider.');
    }
    return context;
};
