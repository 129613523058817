import "../styles/loader.scss";
import { BarLoader } from "react-spinners"


export const Loader = () => {
    return (
        <div className="loader-con">
            <BarLoader color="#27AAE1" />
        </div>
    )
}