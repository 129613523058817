import "../styles/button.scss";
import { ReactNode, useRef } from "react";

interface Props {
    upload?: boolean;
    onClick?: () => void;
    children: ReactNode;
    onFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Button = (props: Props) => {
    const input_ref = useRef<HTMLInputElement>(null!);

    const handle_click = () => {
        if (props.upload && input_ref.current) {
            input_ref.current.click();
        } else {
            if (props.onClick) props.onClick();
        }
    }

    const handle_input = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onFile) props.onFile(event);
    }

    return (
        <div className="button-wrapper">
            <button className="button" onClick={handle_click}>
                {props.children}
            </button>

            <input
                className="hidden-button"
                type="file"
                accept="document/*"
                onChange={handle_input}
                multiple={true}
                ref={input_ref}
            />
        </div>
    )
}