import "../styles/search.scss"
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    query: string;
    setQuery: (query: string) => void;
    runQuery: () => void;
    loading?: boolean;
}

export const SearchBar = (props: Props) => {
    return (
        <div className="search-bar">
            <div className="search-bar-header">
                What would you like to know?
            </div>
            <div className="search-bar-text-area-wrapper">
                <textarea value={props.query} onChange={(e: any) => props.setQuery(e.target.value)} rows={8} />
                <div className="bottom-bar">
                    {props.query.length > 0 && <div className="search-bar-clear" onClick={() => props.setQuery("")}>Clear</div>}

                    <button className="search-bar-search-button" onClick={props.runQuery}>
                        <FontAwesomeIcon icon={props.loading ? faSpinner : faSearch} id={props.loading ? "search-bar-search-button-loading" : ""} />
                    </button>
                </div>
            </div>
        </div>
    );
}