import "../styles/pdf-view-modal.scss";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect, useState } from "react";
import { client } from "../api/api.base";

interface Props {
    path: string;
    page?: number;
}

export const PdfViewModal = (props: Props) => {
    const [local_path, setLocalPath] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const load = async () => {
        setLoading(true);

        try {
            const res = await client().get(props.path, {
                responseType: 'arraybuffer',
            });
            const data = res.data;


            // Create a local URL for the PDF
            const pdfBlob = new Blob([data], { type: 'application/pdf' });
            const localURL = URL.createObjectURL(pdfBlob);

            setLocalPath(localURL);
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (props.path) load();
    }, [props.path]);

    return (
        <div className="pdf-view-modal" style={{
            height: "100%"
        }}>

            {local_path && <Viewer fileUrl={local_path} initialPage={props.page ? props.page - 1 : 0} plugins={[defaultLayoutPluginInstance]} />}
        </div>
    )
}