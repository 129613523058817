import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware';

export interface User {
    name: string;
    token: string;
}

// type UserStore = {
//     user: User;
//     setUser: (user: User) => void
// }

export const useUserStore = create(
    persist(
        (set, get) => ({
            user: null as User | null,
            setUser: (user: User | null) => set({ user }),
        }),
        {
            name: 'user-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);


// Extract the users intials from a users name
export const getInitials = (name: string) => {
    const names = name.split(" ");
    let initials = "";

    names.forEach((name) => {
        initials += name[0];
    });

    if (names.length > 1)
        return names[0][0] + names[names.length - 1][0];
    else
        return initials;
}
