import "../styles/home.view.scss";
import { useEffect, useState } from "react";
import { SearchBar } from "../comps/Search";
import { Logo } from "../comps/Logo";
import { Footer } from "../comps/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faFile, faFileAlt, faFileArchive, faFileInvoice, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../comps/Header";
import { useUserStore } from "../stores/userStore";
import { useNavigate } from "react-router-dom";
import { ResourceResult, Source } from "../model/resource.model";
import { Loader } from "../comps/Loader";
import { api_url } from "../api/api.base";
import { postQuery } from "../api/query.api";
import { Error } from "../comps/Error";
import { useModals } from "../hooks/ModalProvider";
import { PdfViewModal } from "../comps/PdfViewModal";

export const HomeView = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [tags, setTags] = useState<string[]>()
    const [loading, setLoading] = useState(false);
    const [resource_results, setResourceResults] = useState<Source[]>([]);
    const [result, setResult] = useState("");
    const [error, setError] = useState("");

    // Find results
    const search = async () => {
        setError("");
        setResourceResults([]);
        setResult("");
        setLoading(true);
        try {
            const res = await postQuery(query);
            setResult(res.answer);
            setResourceResults(res.sources);
        } catch (err) {
            setError("Query failed.");
        }

        setLoading(false);
    }

    const no_context_present = (context: string) => {
        // Check if this string is present in the context: "does not give information"
        return result.toLowerCase().includes("the provided context does not");
    }


    return (
        <div className="view home-view">
            <Header action={
                <button className="button header-action-button" onClick={() => navigate("/resources")}>
                    <FontAwesomeIcon icon={faFileAlt} />
                    Resources
                </button>
            } />

            <div className="content home-content">
                {/* Toolbar */}
                <SearchBar query={query} setQuery={setQuery} loading={loading} runQuery={search} />

                <Error error={error} />

                {!loading && result && (
                    <div className="result-area">
                        <div className="result-header">
                            Results
                        </div>

                        <div className="result">
                            {result}
                        </div>

                        {!no_context_present(result) && <div className="sources">
                            <div className="title">
                                Sources
                            </div>
                            <div className="sources-list">
                                {resource_results?.map((result: Source, i: number) => (
                                    <SourceItem source={result} key={i} />
                                ))}
                            </div>
                        </div>}
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}

const SourceItem = ({ source }: { source: Source }) => {
    const [open, setOpen] = useState(false);
    const modals = useModals();

    // Open pdf
    const openPdf = () => {
        const path = `/pdf/pdf/${source.pdf.id}`;

        modals.create({
            title: source.pdf.name,
            content: <PdfViewModal path={path} page={source.page} />
        })
    }

    return (
        <div className="source" id={open ? "source-drop-down-open" : "source-drop-down-closed"}>
            <div className="source-header">
                <div className="source-details" onClick={openPdf}>
                    <FontAwesomeIcon icon={faFileInvoice} />
                    {source.pdf.name}
                </div>
                <div className="source-drop-down" onClick={() => setOpen(!open)} >
                    <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
                </div>
            </div>
            {open && <div className="source-content">
                "...{source.text}..."
            </div>}
        </div>
    );
}