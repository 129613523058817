import "../styles/header.scss";
import { ReactNode, useState } from "react"
import { Logo } from "./Logo";
import { getInitials, useUserStore } from "../stores/userStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Props {
    action: ReactNode;
}

export const Header = (props: Props) => {
    return (
        <div className="header">
            <div className="actions">
                {props.action}
            </div>

            <div className="logo">
                <Logo />
            </div>

            <div className="profile">
                <Profile />
            </div>
        </div>
    )
}

export const Profile = () => {
    const [open, setOpen] = useState(false);
    const user = useUserStore((state: any) => state.user);
    const setUser = useUserStore((state: any) => state.setUser);
    const navigate = useNavigate();

    // Handle logging out a user
    const logout = async () => {
        setUser(null);
        navigate("/login");
    }

    return (
        <div className="profile-head" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <div className="profile-head-photo">
                <div className="profile-initials">
                    {getInitials(user?.name || "")}
                </div>
            </div>

            <div className="profile-head-hidden-hover" id={open ? "profile-menu-open" : "profile-menu-close"} />

            <div className="profile-menu" id={open ? "profile-menu-open" : "profile-menu-close"}>
                <div className="profile-overview">
                    <div className="profile-overview-photo">
                        <div className="profile-initials">
                            {getInitials(user?.name || "") || ""}
                        </div>
                    </div>

                    <div className="profile-overview-content">
                        <div className="profile-overview-name">
                            {user?.name || ""}
                        </div>
                        <div className="profile-overview-role">
                            Admin
                        </div>
                    </div>
                </div>

                <div className="profile-menu-items">
                    <div className="profile-menu-item" onClick={logout}>
                        <FontAwesomeIcon icon={faSignOut} />
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}