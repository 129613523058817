import { client } from "./api.base"

// Get the resources that are accesible to a user
export const fetchResources = async () => {
    const res = await client().get("/pdf/resources");
    console.log("res: ", res); // For testing
    return res.data;
}

// Get a specific resource
export const fetchResource = async (id: number) => {
    const res = await client().get(`/resources/${id}`);
    return res.data;
}

// Upload a resource
export const postResource = async (file: any) => {
    // Create a new form data object
    const formData = new FormData();

    formData.append("file", file);

    const res = await client().post("/pdf/upload/", formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        }
    });

    return res.data;
}

// Remove a resource
export const removeResource = async (id: string | number) => {
    const res = await client().delete(`/pdf/remove/${id}`);
    return res.data;
}