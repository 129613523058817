import { createBrowserRouter } from "react-router-dom";
import { HomeView } from "../views/HomeView";
import { ResourcesView } from "../views/ResourcesView";
import { LoginView } from "../views/LoginView";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeView />
    },
    {
        path: "/resources",
        element: <ResourcesView />
    },
    {
        path: "/login",
        element: <LoginView />
    }
]);

