import "./styles/index.scss"
import "react-toastify/dist/ReactToastify.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/root.router';
import { ToastContainer } from "react-toastify";
import { Worker } from '@react-pdf-viewer/core';
import { ModalProvider } from "./hooks/ModalProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <ModalProvider><RouterProvider router={router} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          closeButton={false}
        />
      </ModalProvider>
    </Worker>
  </React.StrictMode>
);