import "../styles/resources-view.scss";
import { useEffect, useRef, useState } from "react"
import { Resource } from "../model/resource.model"
import { useUserStore } from "../stores/userStore"
import { fetchResources, postResource, removeResource } from "../api/resources.api";
import { Header } from "../comps/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faEllipsisVertical, faExternalLink, faLink, faTrash, faTrashAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../comps/Loader";
import { Error } from "../comps/Error";
import { useNavigate } from "react-router-dom";
import { Button } from "../comps/Button";
import { Footer } from "../comps/Footer";
import { toast } from "react-toastify";
import { api_url } from "../api/api.base";
import { useModals } from "../hooks/ModalProvider";
import { PdfViewModal } from "../comps/PdfViewModal";


export const ResourcesView = () => {
    const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user);
    const [resources, setResources] = useState<Resource[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [uploading, setUploading] = useState(false);

    // Load the resources 
    const load = async () => {
        setLoading(true);
        setError("");

        try {
            const res = await fetchResources();
            setResources(res);
        } catch (err) {
            setError("Oh oh. Something went wrong.");
        }

        setLoading(false);
    }

    // Handle uploading a new resource, open the modal
    const upload = async (event: any) => {
        setUploading(true);
        setError("");

        // Create new toast
        const upload_toast = toast("Uploading resource...", {
            progress: 100,
            autoClose: false,
            closeOnClick: false,
        });

        try {
            const selectedFile = event.target.files[0];
            const res = await postResource(selectedFile);
            await load();
        } catch (err) {
            console.log("Failed to upload the files: ", err);
            toast.update(upload_toast, {
                type: "error",
                render: "Failed to upload resource.",
                autoClose: 2000,
            });
            setError("Failed to upload resource.");
        }

        toast.done(upload_toast);
        setUploading(false);
    }



    useEffect(() => {
        if (user) load();
    }, [user]);

    return (
        <div className="view view-resources">
            <Header action={
                <div className="header-title">
                    <div className="back-button" onClick={() => navigate("/")}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div className="title">
                        Resources
                    </div>
                </div>
            } />


            <div className="resources">
                <div className="resources-toolbar">
                    <div className="title">
                        Your resources
                    </div>
                    <Button upload onFile={upload}>
                        <FontAwesomeIcon icon={faUpload} />
                        Upload
                    </Button>
                </div>

                <div className="resources-results">
                    {resources?.map((resource: Resource, i: number) => (
                        <ResoureItem key={i} resource={resource} remove={(r: Resource) => setResources(
                            resources.filter((res: Resource) => res.id !== r.id)
                        )} />
                    ))}
                </div>

                {!loading && !error && resources?.length === 0 && (
                    <div className="no-results">
                        No resources found. Upload a resource to get started.
                    </div>
                )}
                {loading && <Loader />}
                {error && <Error error={error} />}

            </div>

            <Footer />
        </div >
    );
}

const ResoureItem = ({ resource, remove }: { resource: Resource, remove: any }) => {
    const modals = useModals();
    const [open, setOpen] = useState(false);
    const ref = useRef<any>(null);

    // Handle opening the pdf file
    const openFile = () => {
        // Create the path
        const path = `/pdf/pdf/${resource.id}`;

        modals.create({
            title: resource.name,
            content: <PdfViewModal path={path} />
        })

        // window.open(`${api_url}/static/pdf/${resource.path}`, "_blank");
    }

    // Handle removing a pdf file
    const removeFile = async () => {
        // Create nwe toast
        const remove_toast = toast("Removing resource...", {
            autoClose: false,
            closeOnClick: false,
        });

        try {
            const res = await removeResource(resource.id);
            toast.update(remove_toast, {
                type: "success",
                render: "Resource removed.",
                autoClose: 2000,
            });
        } catch (err) {
            toast.update(remove_toast, {
                type: "error",
                render: "Failed to remove resource.",
                autoClose: 2000,
            });
        }

        remove(resource)
    }

    // Hanlde clicking outside the menu, close the menu
    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (ref.current && !ref?.current?.contains(event.target)) setOpen(false);
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="resource-item">
            <div className="name">
                {resource?.name}
            </div>
            <div className="actions" ref={ref}>
                <button className="action-button" onClick={() => setOpen(!open)}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </button>

                <div className="menu" id={open ? "menu-open" : "menu-closed"}>
                    <div className="menu-item" onClick={openFile}>
                        <FontAwesomeIcon icon={faExternalLink} />
                        Open
                    </div>
                    <div className="menu-item" onClick={removeFile}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                        Delete
                    </div>
                </div>
            </div>
        </div>
    );
}
