
import "../styles/login-view.scss";
import { useState } from "react"
import { postLogin } from "../api/auth.api"
import { Logo } from "../comps/Logo"
import { Button } from "../comps/Button"
import { Footer } from "../comps/Footer";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../stores/userStore";


export const LoginView = () => {
    const navigate = useNavigate();
    const setUser = useUserStore((state: any) => state.setUser);
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const login = async () => {
        setLoading(true);

        try {
            const auth_result = await postLogin(username, password);

            if (auth_result?.access) {
                localStorage.setItem("access_token", auth_result.access);
                localStorage.setItem("refresh_token", auth_result.refresh);
                setUser({
                    name: "Mitchell Nanson",
                    token: auth_result.access
                }
                )
                navigate("/");
            } else {
                setError("Failed to login. Double check your password and try again.");
            }

            console.log(auth_result);
        } catch (err) {
            setError("Failed to login. Double check your password and try again.");
        }

        setLoading(false);
    }

    return (
        <div className="view login-view">
            <div className="login-logo-con">
                <Logo />
            </div>

            <div className="login-header">
                <h1>Login</h1>
                <p>Enter your details to log into your account.</p>
            </div>

            <div className="form">
                <div className="input-wrapper">
                    <label>Username</label>
                    <input className="input" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                </div>
                <div className="input-wrapper">
                    <label>Password</label>
                    <input className="input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" type="password" />
                </div>
                <Button onClick={login}>
                    {loading ? "Loading..." : "Login"}
                </Button>
            </div>

            <Footer />
        </div>
    )
}