import axios from "axios";

// Pick the right URL
const prod_url = "https://api-ai.techt.app";
const dev_url = prod_url;
// const dev_url = "http://172.19.0.3:8000";

// Export the URL
export const api_url = process.env.NODE_ENV === "production" ? prod_url : dev_url;

// Export the API
export const client = () => {
    // Try to get the token from local storage
    const user = localStorage.getItem('user-storage');
    const token = user ? JSON.parse(user)?.state?.user?.token : null;

    return axios.create({
        baseURL: api_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : undefined,
        },
    });
};
