import { client } from "./api.base"

// Log a user in
export const postLogin = async (username: string, password: string) => {
    const res = await client().post('/token/', {
        username,
        password
    });

    return res.data;
}