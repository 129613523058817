import { client } from "./api.base"

export const postQuery = async (query: string) => {
    const res = await client().post('/pdf/query/', { query },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    return res.data;
}