import "../styles/error.scss";

interface Props {
    error: string;
}

export const Error = (props: Props) => {
    return (
        <div className="error-con">
            {props.error}
        </div>
    )
}